<template>
  <v-layout row justify-center :my-5="$vuetify.breakpoint.mdAndUp">
    <v-flex xs12 sm12 md11 lg7 xl5>
      <v-row class="pb-2" no-gutters>
        <v-col cols="4">
          <v-btn to="/terms-and-conditions" block text>Terms And Conditions</v-btn>
        </v-col>
        <v-col cols="4" class="px-1">
          <v-btn to="/privacy-policy" block text>Privacy Policy</v-btn>
        </v-col>
        <v-col cols="4">
          <v-btn to="/fees-and-charges" color="primary" block depressed>Fees and Charges</v-btn>
        </v-col>
      </v-row>
      <v-card>
        <v-flex xs12 py-4 :px-5="$vuetify.breakpoint.mdAndUp" :px-4="$vuetify.breakpoint.smAndDown">
          <p class="headline font-weight-bold">Fees and Charges</p>
          <span class="subheading font-weight-bold">For Clients</span>
          <p class="justifyText">
            Clients shall be charged based on the proposals made by the lawyers they hired. If the client selects a lawyer who offers free legal service, then no payment is required. 
          </p>
          <span class="subheading font-weight-bold">For Lawyers</span>
          <p class="justifyText">
            Lawyers shall only be charged upon withdrawal of their earning from their wallet. A minimal fee of <span class="primary--text">1% of the amount withdrawn plus ₱100.00</span> shall be charged to cover bank deposit charge, service fee, and maintenance of the website
          </p>
          <span>When bidding on a task:</span>
          <ul class="mb-3">
            <li>Lawyers can make one bidding for every task posted by the clients.</li>
            <li>Lawyers may choose to provide Free Legal Service or charge the client with a minimum bid of <span class="primary--text">₱200</span>.</li>
          </ul>
          <span class="font-weight-bold title">How to Add Funds</span>
          <p class="justifyText">
            Clients can deposit funds to our select remittance centers and banking institutions. Minimum amount that may be deposited is <span class="primary--text">₱200</span>. Verification of payment during working days <span class="primary--text">(Monday to Saturday, 9:00 am to 4:00 pm for Remittances and 9:00 am to 3:00 pm for Bank Transactions)</span> is within 1 to 3 hours from the time the receipt is uploaded.
          </p>
          <p class="justifyText"><span class="font-weight-bold primary--text">NOTE:</span> If you choose to deposit through banking channels, please be reminded that some banks may not be available on Saturdays.</p>
          <span>Below are the list of our select Remittance Centers and Banking Institutions:</span>
          <ul>
            <div v-for="center in paymentCenters" :key="center.name">
              <li>{{ center.name }}</li>
            </div>
          </ul>
          <v-btn text color="primary" class="d-flex">How to deposit funds?</v-btn>
        </v-flex>
      </v-card>
    </v-flex>
  </v-layout>
</template>

<script>
import InfoService from '@/services/InfoService'

export default {
  data () {
    return {
      paymentCenters: []
    }
  },
  methods: {
    init () {
      InfoService.getPaymentMethods()
        .then(res => {
          let remit = res.data.remittanceCenters
          let banks = res.data.banks
          this.paymentCenters = remit.concat(banks)
        })
    }
  },
  created () {
    this.init()
  }
}
</script>

<style scoped>

</style>